export const LIKERT_SCALE_CHART_OPTIONS = {
  chart: {
    type: "bar",
    width: 800,
    height: 80,
  },
  title: {
    text: "",
  },
  credits: { enabled: false },
  tooltip: {
    enabled: true,
  },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    min: 0,
    visible: false,
    title: {
      text: "Percent",
    },
  },
  legend: {
    reversed: true,
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: "percent",
      dataLabels: {
        enabled: true,
        // format: "{point.percentage:.1f}%",
      },
    },
  },

  colors: [
    
  ],
  series: [],
};

export const RANK_ORDER_CHART_OPTIONS = {
  chart: {
    type: "bar",
    // width: 456,
    height: 80,
  },
  title: {
    text: "",
  },
  credits: { enabled: false },
  tooltip: { enabled: false },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    min: 0,
    visible: false,
    title: {
      text: "Percent",
    },
  },
  legend: {
    reversed: true,
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: "percent",
      dataLabels: {
        enabled: true,
        allowOverlap: true,
        // format: "{point.percentage:.1f}%",
      },
    },
  },

  colors: [],
  series: [],
};

export const GRID_TYPE_CHART_OPTIONS = {
  chart: {
    type: "bar",
    // width: 456,
    height: 80,
  },
  title: {
    text: "",
  },
  credits: { enabled: false },
  tooltip: { enabled: false },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    min: 0,
    visible: false,
    title: {
      text: "Percent",
    },
  },
  legend: {
    reversed: true,
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: "percent",
      dataLabels: {
        enabled: true,
        allowOverlap: true,
        // format: "{point.percentage:.1f}%",
      },
    },
  },

  colors: [],
  series: [],
};

